import React from "react";
import Layout from "../../components/layout";
import MaartenEnHeiline from '../../components/portfolio/maartenEnHeiline.component';


export default function MaartenEnHeilineGalleryPage(props){


  return (
    <Layout>
    <MaartenEnHeiline/>
  </Layout>
  )
}



